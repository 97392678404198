export const displayImg = (n, NoOfImgs, block) => {
    for (let i = 0; i < NoOfImgs; i++) {
        if (i == n) {
            document.getElementById('img-' + block + '-' + i).style.display = 'block';
            document.getElementById('dot-' + block + '-' + i).style.backgroundColor = colorPalette.darkbrown;
        }
        else {
            document.getElementById('img-' + block + '-' + i).style.display = 'none';
            document.getElementById('dot-' + block + '-' + i).style.backgroundColor = colorPalette.brown;
        }
    }
}

let k = 0;
const projectSlider = NoOfImgs => {
    (k >= NoOfImgs) && (k = 0);
    for (let i = 0; i < NoOfImgs; i++) {
        if (i == k) {
            document.getElementById('img-project-' + i).style.display = 'block';
            document.getElementById('dot-project-' + i).style.backgroundColor = colorPalette.darkbrown;
        }
        else {
            document.getElementById('img-project-' + i).style.display = 'none';
            document.getElementById('dot-project-' + i).style.backgroundColor = colorPalette.brown;
        }
    }
    k++;
}

export let nextProject;
export function projectInterval(NoOfImgs) {
    nextProject = setInterval(() => projectSlider(NoOfImgs), 7000)
    projectSlider(NoOfImgs)
}

let l = 0;
const techSlider = NoOfImgs => {
    (l >= NoOfImgs) && (l = 0);
    for (let i = 0; i < NoOfImgs; i++) {
        if (i == l) {
            document.getElementById('img-tech-' + i).style.display = 'block';
            document.getElementById('dot-tech-' + i).style.backgroundColor = colorPalette.darkbrown;
        }
        else {
            document.getElementById('img-tech-' + i).style.display = 'none';
            document.getElementById('dot-tech-' + i).style.backgroundColor = colorPalette.brown;
        }
    }
    l++;
}

export let nextTech;
export function techInterval(NoOfImgs) {
    nextTech = setInterval(() => techSlider(NoOfImgs), 7000)
    techSlider(NoOfImgs)
}

let m = 0;
const highlightsSlider = NoOfImgs => {
    (m >= NoOfImgs) && (m = 0);
    for (let i = 0; i < NoOfImgs; i++) {
        if (i == m) {
            document.getElementById('img-highlights-' + i).style.display = 'block';
            document.getElementById('dot-highlights-' + i).style.backgroundColor = colorPalette.darkbrown;
        }
        else {
            document.getElementById('img-highlights-' + i).style.display = 'none';
            document.getElementById('dot-highlights-' + i).style.backgroundColor = colorPalette.brown;
        }
    }
    m++;
}

export let nextHighlights;
export function highlightsInterval(NoOfImgs) {
    nextHighlights = setInterval(() => highlightsSlider(NoOfImgs), 7000)
    highlightsSlider(NoOfImgs)
}

export const colorPalette = {
    cream: '#FFF8DC',//'#FFFFCC',
    pinkish: '#F4F0CB',
    lightbrown: '#DED29E',
    brown: '#B3A580',
    darkbrown: '#685642',
    white: '#FFFFFF',
    black: '#000000',
    blue: '#4169E1',
    redish: '#C25B56',
    goldenrod: 'goldenrod',
    gold: '#FFBE00',
    carolina: '#35A2EB',
}

export const lblDev = ['Start', 'Initial Draft', 'UI/UX Designing', 'ERD + DB Development', 'Login + Theme + Home',
'User Interface', 'Admin Panel', 'API Writing', 'API Integration', 'Deployment + Testing',
'Backlog + Debugging', 'Beta Release', 'Final + Training']

export const lblWeeks = [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30]; 

export const lblExample = ['Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];

//export const lblHeader = []