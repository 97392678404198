import React from 'react'

function MultithreadProgramming() {
    return (
        <div className='blog-text'>
            <p style={{textAlign:'right', color: 'orange', textShadow:'1px 1px 1px black'}}>Last Updated: 15th March, 2023</p>
            <p>
                When we run a program or process or method multiple times to perform multiple operations
                concurrently, it is termed as multithreading programming. By default each program has one
                thread. Let us consider the following code:</p>

            <div className="container mt-3">
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" data-bs-toggle="tab" href="#vb">VB.Net</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="tab" href="#cs">C#</a>
                    </li>
                </ul>

                <div className="tab-content">
                    <div id="vb" className="container tab-pane active"><br />
                        <pre><code>
                            <ol>
                                <li>Imports System.Threading</li>
                                <li>Dim EmailThread As Thread</li>
                                <li>EmailThread = New Thread(AddressOf sendEmails)</li>
                                <li>EmailThread.Start(credentials)</li>
                            </ol>
                        </code></pre>
                    </div>
                    <div id="cs" className="container tab-pane fade"><br />
                        <pre><code>
                            <ol>
                                <li>Using System.Threading;</li>
                                <li>Thread EmailThread;</li>
                                <li>EmailThread = new Thread(sendEmails);</li>
                                <li>EmailThread.Start(credentials);</li>
                            </ol>
                        </code></pre>
                    </div>
                </div>
            </div>
            <p>
                <strong>Background</strong>: We need to send bulk emails. If we send emails in a queue, it can take time to
                send one by one, however if we send multiple emails simultaneously, it can save our time
                and bulk emails can be sent in short time.</p>
            <p>
                <strong>Explanation</strong>: ‘sendEmails’ is a function used to send an email. ‘credentials’ is argument of
                type arraylist that include all information required to send email that is ‘To’ and ‘From’
                email ids, Password, SMTP Server, SSL, Port etc.</p>
            <ol>
                <li>First we need System.Threading namespace to import</li>
                <li>‘EmailThread’ is a variable declared as Thread.</li>
                <li>
                    ‘New’ keyword is used to instantiate ‘Thread’ delegate that will be used to associate
                    its instance with function ‘sendEmails’ through operator ‘AddressOf’, and assigned it to
                    variable ‘EmailThread’. In C# ‘AddressOf’ operator isn’t needed.
                </li>
                <li>
                    ‘sendEmails’ function receives argument ‘credentials’ as explained above which is
                    passed while starting thread by using ‘EmailThread.Start’ method.
                </li>
            </ol>
            <p>
                You can place code that start a new thread in a timer tick event or loop to start as many
                threads as you want. There are many properties and methods available to manage and control
                threads.</p>
            <p>
                Examples of properties are:<br />
                <strong>ThreadState</strong> Gets current state of thread i.e. running, stopped, suspended etc.<br />
                <strong>Priority</strong>: Gets or sets scheduling priority of thread from these values Highest, AboveNormal,
                Normal, BelowNormal, Lowest.<br />
                <strong>CurrentCulture</strong>: Gets or Sets culture for current thread i.e. default format of date, time,
                currency etc.<br />
                <strong>IsAlive</strong>: Returns true if thread is executing, otherwise false.<br />
                <strong>Name</strong>: Gets or sets name of the thread. Returns null if no name is set.
            </p>
            <p>
                Examples of methods are:<br />
                <strong>Start()</strong>: Begins thread execution.<br />
                <strong>Sleep(Integer)</strong>: Suspends thread for specified milliseconds in integral value.<br />
                <strong>Abort()</strong>: Raises an exception that can be handled in code, usually used to terminate thread.<br />
                <strong>Interrupt()</strong>: Throws an exception when thread is blocked. You can handle this exception in
                your code.<br />
                <strong>Join()</strong>: Blocks the calling thread say A.Start() until the thread say B.Join() whose Join
                method is called has completed.<br />
            </p>
        </div>
    )
}

export default MultithreadProgramming