import React, { useEffect, useState } from 'react';
import { blogs } from '../data/BlogsInfo';
import { Link, Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { colorPalette } from '../SharedCode';

function Blog() {
  const [blogId, setBlogId] = useState(null);
  const blogRoute = useLocation().pathname.split('/').pop();
  let nestedRouteIndex = null;
  for (let i = 0; i < blogs.length; i++) {
    if (blogRoute == blogs[i].route) {
      nestedRouteIndex = i;
      break;
    }
  }
  blogId != nestedRouteIndex && setBlogId(nestedRouteIndex);
  const selectedBlog = index => {
    (nestedRouteIndex == null) && (index = 0);
    const elementList = document.getElementsByClassName('blog');
    for (let i = 0; i < elementList.length; i++) {
      if (index == i) {
        elementList[i].style.backgroundColor = colorPalette.darkbrown;
        elementList[i].style.color = colorPalette.cream;
      }
      else {
        elementList[i].style.removeProperty('background-color');
        elementList[i].style.color = colorPalette.darkbrown;
      }
    }
  }
  useEffect(() => {
    document.title = "blogs - Full Stack + UX/UI Developer";
  }, []);
  useEffect(() => {
    selectedBlog(blogId);
  }, [blogId]);

  return (
    <div className='container'>
      <div className='row' style={{marginTop: '30px'}}>
        <div className='col-12 col-lg-4 col-blog'>
          <div className="stat-heading mb-1">Blogs</div>
          {
            blogs.map((x, i) => (
              <div key={'blog-' + i}>
                <div className='blog-sr'>{i + 1}</div>
                <Link to={x.route}><div className='blog' onClick={() => { setBlogId(i) }} style={{ backgroundColor: i == blogId - 1 && colorPalette.darkbrown, color: i == blogId - 1 && colorPalette.cream }}>{x.topic}</div></Link>
              </div>
            ))
          }
        </div>
        <div className='col-12 col-lg-8 col-portfolio' style={{maxHeight:'500px'}}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Blog