import React, { useEffect } from 'react';
import Piechart from './visuals/Piechart';
import { statInfo, commentInfo } from '../data/StatInfo';
import { colorPalette, displayImg, highlightsInterval, nextHighlights } from '../SharedCode';
import { imgHighlights } from '../data/Images';

function Stat() {
    const imgNo = imgHighlights;
    let pieAspectRatio;
    pieAspectRatio = window.screen.width > 991 ? pieAspectRatio = 1.7 : pieAspectRatio = 1.2;
    let l = 0; let m = 0; let n = 0; let r = 0;
    for (let i = 0; i < statInfo.length; i++) {
        l = l + statInfo[i].completed
        m = m + statInfo[i].pulledOut
        r = r + (statInfo[i].rating * statInfo[i].completed)
    }
    n = l + m;
    const objData = {
        platform: "overall",
        completed: l,
        pulledOut: m,
        rating: r / l,
    }
    statInfo.unshift(objData)
    let pieData;
    pieData = objData;
    useEffect(() => {
        return () => {
            statInfo.shift();
        };
    }, []);

    const toggleStat = id => {
        let statIds = [];
        for (let i = 0; i < statInfo.length; i++) {
            statIds.push('stat-' + statInfo[i].platform)
            if (id == 'stat-' + statInfo[i].platform) {
                pieData = statInfo[i];
            }
        }
        const eleStat = document.getElementById(id);
        if (!eleStat.classList.contains('show')) {
            for (let i = 0; i < statIds.length; i++) {
                id != statIds[i] && document.getElementById(statIds[i]).classList.remove('show')
            }
        }
    }

    useEffect(() => {
        document.title = "Full Stack + UX/UI Developer - Adeel Rauf";
      }, []);

      useEffect(() => {
        highlightsInterval(imgNo.length);
        return () => {
            clearInterval(nextHighlights);
        }
    }, []);

    return (
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-12 col-stat' style={{ maxHeight: '650px' }}>
                    <div className="stat-heading"><b>Highlights of Past Work</b></div>
                    {
                        imgNo.map((x, i) => (
                            <img src={x} key={'img-highlights-' + i} id={'img-highlights-' + i} style={{ display: i == 0 ? 'block' : 'none' }} className="img-fluid fade-slide" alt="Highlights" />
                        ))
                    }
                </div>
                {
                    imgNo.map((x, i) => (
                        <div key={'dot-highlights-' + i} id={'dot-highlights-' + i} onClick={() => { displayImg(i, imgNo.length, 'highlights'); clearInterval(nextHighlights) }} style={{ backgroundColor: i == 0 && colorPalette.col7, cursor: 'pointer' }} className='img-dot'></div>
                    ))
                }
            </div>
            <div className='row stat justify-content-center'>
                <div className='col-12 col-lg-6 col-stat'>
                    {
                        statInfo.map((x, i) => (
                            <div key={'platform-' + i} ><div className="stat-heading" data-bs-toggle="collapse" data-bs-target={"#stat-" + x.platform} onClick={() => toggleStat('stat-' + x.platform)}>{x.platform}</div>
                                <div id={"stat-" + x.platform} className={x.platform == 'overall' ? "collapse show stat-table" : "collapse stat-table"}>
                                    <div>Total Projects:</div><div>{x.completed + x.pulledOut}</div><br />
                                    <div>Completed:</div><div>{x.completed}</div><br />
                                    <div>Pulled Out:</div><div>{x.pulledOut}</div><br />
                                    <div> Rating:</div><div>{'5 / ' + Number(x.rating).toFixed(2)}</div>
                                </div></div>
                        ))}
                </div>
                <div className='col-12 col-lg-6 col-stat' style={{ backgroundColor: colorPalette.cream }}>
                    <Piechart lbl={["Successful", "Pulled out"]} data={[pieData.completed, pieData.pulledOut]} colours={[colorPalette.redish, colorPalette.brown]} lblColor={colorPalette.darkbrown} title={'Rating 5 / ' + Number(pieData.rating).toFixed(2)} titleColor={colorPalette.redish} pieAR={pieAspectRatio} />
                </div>
                <div className='row g-0'>
                    <div className='col-12 col-stat'>
                        <div className="stat-heading" style={{ cursor: 'auto' }}>
                            Testimonial
                        </div>
                        {
                            commentInfo.map((x, i) => (
                                <div id={"comment-" + i} className="comment-table" key={"comment" + i}>
                                    <div>Platform:</div><div>{x.platform}</div>
                                    <div>Project:</div><div className='proj' title={x.project}>{x.project}</div>
                                    <div className='comment'>{x.comment}</div><div className='d-none'></div>
                                    <div>Client:</div><div>{x.client}</div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stat