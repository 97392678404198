import React from 'react'

export default function MobileAppDevSteps() {
    return (
        <div className='blog-text'>
            <p style={{textAlign:'right', color: 'orange', textShadow:'1px 1px 1px black'}}>Last Updated: 13th June, 2023</p>
            <p>Different mobile app development companies may have slightly different approach and development strategy. In most cases, it is a team work and the team may consist of 3 - 5 professionals.</p>
               <p>Best Development strategy to save time & cost and to avoid complexity at later state involves the following steps:</p>
            <p>1. <b>Discussion:</b> It is important to discuss the project purpose, project goals and features with the client in detail.</p>
            <p>2. <b>Proposal Writing:</b> Once the discussion is finished, a proposal is written based on the discussion that includes features, tech stack, time frame, rates, and any future recommendations. It may take a few iterations back and forth to capture the client's requirements in writing. Once the proposal is finalized and accepted, it serves as the contract between the client and the professional.</p>
            <p>3. <b>UX/UI Development:</b> The first step of development is to create a neat and clean UX/UI design, including prototyping and branding elements such as custom buttons, icons, and logos. The UX/UI design forms the backbone of the entire project, and any issues with it can lead to project complexities and, in worse cases, project failure. It is important to choose an experienced UX/UI developer who understands the concepts of scalability and data flow. Additionally, it is valuable to have input from the Project Manager during the UX/UI development process.</p>
            <p>4. <b>ER-Diagram OR Database Schema Development:</b> After ux/ui is finished and approved, an ER-diagram is developed based on the ux/ui design. ER-diagram is like a database-schema and it helps backend development process easier and smoother. Database migrations can also be included at this stage. Best approach is to design the ER-diagram/DB-schema by the Project Manager.</p>
            <p>5. <b>Front-end Development:</b> After the UX/UI design is developed and approved, the front-end development can commence using the agreed-upon tech stack. There are some cross-platform technologies available, such as React Native, Flutter, and Ionic, which target both Android and iOS platforms with a single codebase. This stage depends on the completion of the UX/UI development and can be initiated in parallel with ER-diagram development.</p>
            <p>6. <b>APIs and Endpoints Creation:</b> Once the ER-diagram is developed, the first step is to create database migrations and then write APIs and create endpoints to perform CRUD operations on data. This stage is independent of front-end development and can be started as soon as the ER-diagram is designed.</p>
            <p>7. <b>Integration of Endpoints in Front-end:</b> After the UX/UI design is finished and approved, an ER-diagram or database schema is developed based on the UX/UI design. The ER-diagram acts as a blueprint for the backend development process, making it easier and smoother. Database migrations can also be included at this stage. The best approach is to have the ER-diagram/DB-schema designed by the Project Manager.</p>
            <p>8. <b>Alpha Release:</b> After endpoints are integrated into the frontend of the application, application starts working and alpha version is released. There might be some bugs and fixes required in the alpha release of the app, and some part of the app may not work as expected, which is pretty normal with alpha release.</p>
            <p>9. <b>Unit Testing:</b> Unit testing is performed throughout the development, however, after an alpha release, heavy testing is performed by the specialised testing engineer. It also covers up quality assurance and performance testing. Project Manager may also be involved in testing. Some automated testing tools can also be used to perform application testing. Each part of the application is tested to find problems in the app. This is unit testing stage that comes after an Alpha release.</p>
            <p>10. <b>Backlog Writing:</b>  Results of Unit Testing is documented alongwith screenshots wherever required to explain problems with the app. This document is shared with the development team to fix problems. This document is termed as backlog. This is important to note that different companies have their own structure of writing a backlog. Detailed requirements documented in the beginning is also termed as backlog. However, it is always a good practice to have separate backlog for bugs and fixes to track them easily.</p>
            <p>11. <b>Bugs Fixing:</b> After Backlog Writing, process of Bugs Fixing is started. It may take a few iteration to fix bugs, testing, writing backlogs and fixes goes on until an application works as expected.</p>
            <p>12. <b>Beta Release:</b> After bugs are fixed, beta version of the application is released for the audience to use it.</p>
            <p>13. <b>Fine Tuning:</b> Some problems and fixes come across from users feedback, those are documented again in the form of backlogs, and the application is further tuned before final relase.</p>
            <p>14. <b>Deployment:</b> Once an application is ready for launching, an admin panel is deployed to the server, and an application is subscribed to google play store and apple app store for review.</p>
            <p>15. <b>Documentation:</b> Documentation along with screenshots or video explanation is provided to the client to explain him how to operation an Admin Panel to conrol the application.</p>
            <p>16. <b>Development Life Cycle:</b> New features are added into the application by starting again from the first stage, and the cycle goes on.</p>
        </div>
    )
}
