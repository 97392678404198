import React from 'react';
import { useEffect } from 'react';
import { displayImg, nextProject } from '../SharedCode';
import { projectInterval } from '../SharedCode';

function Projects(props) {
    const project = props.project;
    const imgNo = project.imgs;
    
    useEffect(() => {
        projectInterval(imgNo.length);
        return () => {
            clearInterval(nextProject);
        }
    }, [props.project]);

    return (
        <div className='text-center position-relative img-overlay'>
            {
                imgNo.map((x, i) => (
                    <img src={x} key={'img-project-' + i} id={'img-project-' + i} style={{ display: i == 0 ? 'block' : 'none' }} className="img-fluid fade-slide" alt={project.name} />
                ))
            }
            <div className='project-info text-left pt-3'>
                <div style={{ whiteSpace: "pre-wrap" }}>
                    <div className='project-info-txt'>
                        <p><b>Tech Stack: </b>{project.tech}</p>
                        <p><b>URL: </b>{project.URL}</p>
                        <p><b>Description: </b>{project.description}</p>
                        <p><b>Role: </b>{project.role}</p>
                    </div>
                </div>
            </div>
            {
                imgNo.map((x, i) => (
                    <div key={'dot-project-' + i} id={'dot-project-' + i} onClick={() => { displayImg(i, imgNo.length, 'project'); clearInterval(nextProject) }} style={{ backgroundColor: i == 0 && '#C25B56', cursor: 'pointer' }} className='img-dot'></div>
                ))
            }
        </div>
    )
}

export default Projects