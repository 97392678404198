import React from 'react'

function WhoDesignUIUX() {
    return (
        <div className='blog-text'>
            <p style={{textAlign:'right', color: 'orange', textShadow:'1px 1px 1px black'}}>Last Updated: 21st March, 2023</p>
            <p>
            Are you looking for designing your mobile application and/or web application?</p>
            <p>
            First step towards development is ux/ui design. Whom you will choose to design screens of your 
            application, a designer or a developer?</p>
            <p>
            Definitely a designer in most cases, and here you can meet with some 
            serious problems. You are non-technical, so as your designer. You will tell designer, how you want 
            your application to look like, he will design accordingly and will design well. However, your designer 
            wouldn't be able to convert your business logic into a <em>useful</em> design. So, there are two 
            aspects of ux/ui designs, usability of the app and an appearance of the design. A good looking 
            design is not necessary to be a good working application. In bad scenario, your design may not be 
            convertible into a stable, neat and clean frontend. This is fine, you may agree on bringing some 
            change in the frontend design. However, in a worse scenario, your design may not be scalable.</p>
            <p> 
            This is common scenario. Designers don't understand scalability that lead to complexities at later 
            stages. Scalability is a part of usability and a user experience. User is not only an end user but 
            also <em>you</em>, as an app administrator a user of an application. I hardly found any client who 
            had no missing screens in the ux/ui design and also the design was scalable. I thought to share my 
            experience with you. Be careful while choosing the ux/ui designer.</p>
            <p>
            So whom you should choose? Better approach is to find a full stack developer to design your ux/ui 
            designs because he will have deep insigns on frontend as well as backend development. And the best 
            approach is to contract a company who will take a start with a prototype and a ux/ui design, then 
            convert that design into a stable, neat and clean frontend and shall develop the scalable backend 
            accordingly. Hope it will help!</p>
        </div>
    )
}

export default WhoDesignUIUX